import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import BreadCrumb from "../components/BreadCrumb";
import GalleryGrid from "../components/GalleryGrid";
import SEO from "../components/Seo";
const GalleryPage = (props) => {
  const { data } = props;
  return (
    <Layout details={data}>
      <SEO
        pathname="/"
        title={`Nastesco Marine Pte Ltd | Gallery`}
        keywords={["Nastesco Gallery"]}
      />
      <BreadCrumb
        list={[
          {
            title: "Image Gallery",
            href: "/gallery",
            active: true,
          },
        ]}
      />
      <GalleryGrid products={data?.gallery?.nodes} />
    </Layout>
  );
};

export const query = graphql`
  query {
    settings: allSanitySettings {
      nodes {
        id
        address_details {
          address_text
          city
          country
          office_location {
            latitude
            longitude
          }
          site_email
          site_phone
          state
        }
        business_hours {
          closed
          open
        }
        copyright_year
        shipserve_details {
          logo {
            asset {
              url
            }
          }
          trade_id
        }
        site_logo {
          asset {
            url
          }
        }
        site_name
        social_links {
          facebook_link
          linkedin_link
          tweeter_link
        }
      }
    }
    company_profile: allSanityCompanyProfile {
      nodes {
        about_us
        full_description {
          description
          description_image {
            asset {
              url
            }
          }
        }
        policy_details {
          description
          policy_image {
            asset {
              url
            }
          }
        }
      }
    }
    gallery: allSanityGallery {
      nodes {
        _id
        description
        title
        image {
          asset {
            url
          }
        }
      }
      totalCount
    }
  }
`;
export default GalleryPage;
