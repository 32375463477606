import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import voca from "voca";
import slug from "slug";
const GalleryGrid = (props) => {
  const { products } = props;
  const addOrRemoveArray = (arr, item) =>
    typeof arr === Array
      ? arr.includes(item)
        ? arr.filter((i) => i !== item)
        : [...arr, item]
      : [item];
  const itemsPerPage = 6;
  const [pageLimit, setPageLimit] = useState(itemsPerPage);

  return (
    <div className="bg-[#f7f7f7] bg-gradient-to-t from-gray-100  relative z-[1] product-section overflow-hidden">
      <div className=" mx-auto px-16 py-24 max-w-7xl xs:px-3 ">
        <div
          className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 sm:gap-y-16 md:grid-cols-3 [&:not(:focus-visible)]:focus:outline-none"
          id="headlessui-tabs-panel-:Rql9m:"
          role="tabpanel"
          tabIndex="0"
          aria-labelledby="headlessui-tabs-tab-:R1j6l9m:"
        >
          {products &&
            products.slice(0, pageLimit).map((product, index) => {
              const productSlug = `product/${slug(
                voca.lowerCase(product?.title)
              )}-${product?._id}`;
              return (
                <div className="bg-white rounded-lg overflow-hidden  shadow-md hover:shadow-xl">
                  <div className="group relative h-[17.5rem] transform overflow-hidden rounded-4xl">
                    <div className="absolute inset-0 bg-indigo-50">
                      <img
                        alt=""
                        src={product?.image?.asset?.url}
                        className=" inset-0 h-full w-full object-cover transition duration-300 group-hover:scale-110"
                      />
                      <div className="p-[15px] bottom-0 absolute w-full bg-[#5551d7e3]">
                        <p className="font-display text-md font-bold tracking-tight text-white">
                          {product?.title}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        {products.length > pageLimit ? (
          <div className="flex items-center justify-end pt-5 xs:justify-center sm:justify-center">
            <button
              type="button"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              onClick={() => setPageLimit(pageLimit + itemsPerPage)}
            >
              Load More..
              <svg
                aria-hidden="true"
                className="ml-2 -mr-1 w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default GalleryGrid;
